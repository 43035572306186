const categories = [
    {
        name: 'Recipes',
        route: 'recipes',
        image: 'https://res.cloudinary.com/yabuko/image/upload/v1587923802/posts/IMG_8686_tef2rg.jpg'
    },
    {
        name: 'How To',
        route: 'how-to',
        image: 'https://res.cloudinary.com/yabuko/image/upload/v1588012209/posts/20200426_160120_2_y4opkw.jpg'
    },
    {
        name: 'Places',
        route: 'places',
        image: 'https://res.cloudinary.com/yabuko/image/upload/v1590350826/website/IMG_1361_cf9cpd.jpg'
    }
] 

export default categories