import React, { Component } from "react";
import { Image, Flex, Text } from "rebass"
import { SectionTitle } from "@sections"
import { Grid } from "@extend"

export default class extends Component {
  state = { photos: [] };

  async componentDidMount() {
    try {
      const response = await fetch(
        `https://www.instagram.com/graphql/query?query_id=17888483320059182&variables={"id":"${
          this.props.userId
        }","first":${this.props.photoCount},"after":null}`
      );
      const { data } = await response.json();
      const photos = data.user.edge_owner_to_timeline_media.edges.map(
        ({ node }) => {
          const { id } = node;
          const caption = node.edge_media_to_caption.edges[0].node.text;
          const thumbnail = node.thumbnail_resources.find(
            thumbnail => thumbnail.config_width === this.props.thumbnailWidth
          );
          const { src, config_width: width, config_height: height } = thumbnail;
          const url = `https://www.instagram.com/p/${node.shortcode}`;
          return {
            id,
            caption,
            src,
            width,
            height,
            url
          };
        }
      );
      this.setState({ photos });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
    <Flex as="section" flexDirection={'column'}>
    <SectionTitle title={"follow us"}>
        <a href="https://www.instagram.com/kaja.eliza/"                
        target="_blank"
        rel="noopener noreferrer">@kaja.eliza</a>
    </SectionTitle>
    <Grid gap={[4, 1, 0]} columns={[4, 1, 4]} mt={3}>
        {   this.state.photos.length > 0 ? this.state.photos.map(({ src, url, id, caption }) => {
                return (
                    <a
                    key={id}
                    className={'instagram-item'}
                    href={url}
                    style={{lineHeight: 0}}
                    target="_blank"
                    rel="noopener noreferrer"
                    // aria-label=""
                    >
                        <Image
                        alt={caption}
                        src={src}
                        width={['100vw', 'calc(100vw / 4)']}
                        height={'auto'} //auto
                        sx={{ objectFit: 'cover' }}
                        />
                    </a>
                )
            })
            :
            null
        }
    </Grid>
    <Text as="span" className="instagram-link uppercase" pt={25} pb={20} textAlign={'center'} fontSize={25} fontWeight={'800'}><a href="https://www.instagram.com/explore/tags/wearefoodmakers" target="_blank" rel="noopener noreferrer">#wearefoodmakers</a></Text>
    </Flex>
    );
  }
}
