import React from "react"
import { graphql } from "gatsby"
import { Layout, SEO, CategoryItem, Misc } from "@components"
import { SectionTitle } from "@sections"
import Grid from "../components/extend/Grid"
import categories from "../data/index"
import InstagramGallery from "../components/InstagramGallery"
import Ad from "../components/ads/Ad"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const miscPosts = posts.filter(({node}) => node.frontmatter.category === 'misc')
  
  return (
    <Layout location={location} title={siteTitle} posts={posts}>
      <SEO title="We Are Food Makers"/>
      <SectionTitle title={"Explore"} to='categories'/>
      <Grid py={2} gap={1} columns={[3, 1, 1]}>
        {
          categories ? 
          categories.map((category, idx) => {
            return (
              <CategoryItem key={idx} category={category}/>
            )
          })
          : null
        }        
      </Grid>
      <Misc miscPosts={miscPosts}></Misc>
      <Ad style={{height: '150px'}}></Ad>
      <InstagramGallery
        userId={'3128455129'}
        thumbnailWidth={640}
        photoCount={8}
      />      
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }    
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {fileAbsolutePath: {regex: "/index/"}}) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            image
          }
        }
      }
    }
  }
`